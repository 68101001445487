<template>
    <div class="row">
        <div class="col-12 col-lg-6">
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.Name.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.name }}</span>
            </p>
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.VatNumber.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.vatNumber }}</span>
            </p>
        </div>
        <div class="col-12 col-lg-6">
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.Kvk.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.kvk }}</span>
            </p>
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.Iban.Label }} </span>
                <span class="ml-3">{{ legalCompanyDetails.iban }}</span>
            </p>
        </div>
        <div class="col-12 col-lg-6">
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.BicSwift.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.bicSwift }}</span>
            </p>
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.CreditNumber.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.creditNumber }}</span>
            </p>
        </div>
        <div class="col-12 col-lg-6">
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.Street.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.street }}</span>
            </p>
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.Number.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.number }}</span>
            </p>
        </div>
        <div class="col-12 col-lg-6">
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.NumberAdditive.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.numberAdditive }}</span>
            </p>
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.PostalCode.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.postalCode }}</span>
            </p>
        </div>
        <div class="col-12 col-lg-6">
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.City.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.city }}</span>
            </p>
            <p>
                <span class="u-fw-semi-bold">{{ PROVIDER.Order.OrderDetails.LegalCompanyDetails.Country.Label }}</span>
                <span class="ml-3">{{ legalCompanyDetails.countryCode }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

export default {
    name: 'LegalCompanyDetail',
    props: {
        legalCompanyDetails: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            PROVIDER
        }
    }
}
</script>